/* .NavigationItem {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    width: auto;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active
 {
    background-color: #ababab;
    border-bottom: 4px solid #ffffff;
    color: #ffffff;
}
.NavigationItemDrawer {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItemDrawer a {
    color: #000000;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItemDrawer a:hover,
.NavigationItemDrawer a:active,
.NavigationItemDrawer a.active
 {
    background-color: #C02032;
    color: #ffffff !important;
}


@media (min-width: 500px) {

}
*/
.ULnavbar-nav li a.active{
    color: #c02032;
}

.UL-sidebar ul li span {
    display: unset;
}
.UL-sidebar ul li a.active {
    background-color: #c02032;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    display: block;
}

/* .UL-sidebar li.active a {
    background-color: #c02032;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    display: block;
} */

.UL-sidebar ul li .active .UL-cltextfff{
    color: #ffffff;
}

.UL-sidebar ul li .active .UL-tx-white{
    color: #ffffff;
    stroke: #ffffff;
}

div.UL-sidenavformate {
    visibility: hidden;
    display: none;
}

.active > div.UL-sidenavformate {
    visibility: visible;
    display: block;
}
.UL-sidebar .UL-sidenavformate ul li {padding-bottom: 0}
.light .flag-select__option, .dark .flag-select__option{padding:0}
.line-gray:hover {color:#000}
.light .ULtab-vertical {width: 20%;}
.light .ULsliderS:before {background-color:#939598}
#ULVtab2 .ULswitch input:checked + .ULslider:before, #ULVtab2  .ULswitchM input:checked + .ULsliderM:before, #ULVtab2  .ULswitchS input:checked + .ULsliderS:before{
    background-color: #fff
}

.light .ULsliderS {background-color:#fff; border: 1px solid #939598}
.light .ULsliderS:before {bottom: 2px}
.light .flag-select__btn:after, .dark .flag-select__btn:after{display: none}
input:checked ~ .UL-sidebar li a .fa-angle-down, input:checked ~ .UL-sidebar li a .fa-angle-right {display:none}
input:checked ~ .UL-sidebar .menu-flags .flag-select__options {right: -106px}
.light .ex-small-card h3{color:#000}


/* Dark mode css start */
#root .dark, .dark .flag-select .flag-select__options {background-color: #000;color:#fff}
.dark .UL-main-header, .dark .UL-sidebar, .dark .ULtabcontent-vertical, .dark .ULtab-vertical button.active, 
.dark .ULtab-vertical button:hover,  .dark .UL-card-width-extralarge .medium-card,
.dark .ex-small-card {background-color:#282C34}
.dark .UL-main-header {border-bottom: none}
.dark .UL-sidebar {box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.6);}
.dark .ULlogo {fill: #fff}
.dark .UL-sidebar .ul-sidebar-height li a, .dark .label-name, .dark .ULtab-vertical button, .dark .UL-profile-div .UL-border-bt h2,
.dark .UL-profile-div .UL-profile-menu li a, .dark .popup .cl000, .dark .UL-card-width-extralarge .medium-card .card-body .Cell p,
.dark .ex-small-card .s-heading, .dark .UL-pagination .pagn2, .dark .Cell p a {color:#fff}
.dark .tx-indigo {color: #c02032; font-weight:600;font-size:14px}
.dark .ULtabcontent-vertical {padding: 0px 30px}
.dark .ULtabcontent-vertical {border: none}
.dark .main-wrap .UL-contantright,.light .main-wrap .UL-contantright {top: 40px}
.dark .ULtab-vertical {width: 20%;background-color:#0d0e0e;border:none} 
.dark .ULtabcontent-vertical, .light .ULtabcontent-vertical {width: 80%}
.dark .UL-box-shadow {box-shadow: 0 2px 4px rgba(193, 193, 193, 0.12), 0 1px 4px rgba(193, 193, 193, 0.24);display: inline-block;width:100%}
.dark .ULsliderS:before {background-color:#868686;bottom: 3px}
.dark .ULswitchS input:checked + .ULsliderS, .dark .popup {background-color:#000}
.UL-profile-div .UL-border-bt div {color:#c7c7c7}
.dark .popup {border:1px solid rgb(77, 77, 77);box-shadow: 0 2px 4px rgba(193, 193, 193, 0.12), 0 1px 4px rgba(193, 193, 193, 0.24)}
.dark .flag-select .flag-select__options, .light .flag-select .flag-select__options{padding: 10px}
.dark .UL-profile-div,  .dark .UL-card-width-extralarge .medium-card .card-body .table-Row:hover, .dark #action_list {background-color: #000;}
.dark .ULcltextfff li a.cl000 {color: #fff;}
.dark span.cl000 {color: #fff;}
.dark .ULslider, .dark .ULsliderM, .dark .ULsliderS {background-color: transparent}
.dark .export-wfpc {color: #fff; font-weight: bold;}
.export-wfpc {color: #282C34; font-weight: bold;}
#rfs-btn {border: none}
.menuflag ul {bottom: 47px;min-width: 162px;left: unset}
.menuflag ul li span span svg {position: relative;top: 4px}
.menuflag ul li span span {color: #000}
.menu-flags-login ul {min-width: 170px;left: unset;right: 8px;margin-top: -2px}
input:checked ~ .UL-sidebar .menuflag #rfs-btn {padding:0;position: relative;left: 5px}
input:checked ~ .UL-sidebar .menuflag ul {left: 10px}

.pagination-position {position: absolute; bottom: 20px;}


@media screen and (max-width: 1000px) {
    input:checked ~ .UL-sidebar{display: none}
    input:checked ~ .UL-contantright, .UL-contantright div,.main-wrap .UL-contantright {margin-left:0 !important}
    input:checked ~ label.UL-hambargarmenu {margin-left: 0;left: 20px}
    .ul-row .resp-miniclass {width: 100%;max-width: 100%;flex: auto}
    .UL-outer-setting {padding-right: 0}
    .UL-outer-setting .ul-row .padding-card {padding: 0px }
    .month-text span {color:#fff}
    .content1 .Cell{border-bottom: none; width: auto;}
    .Heading div {position: absolute;top: -9999px;left: -9999px }
    .light .content1 .table-Row {    
        background: #FFF
    }
    .content1 .Cell p {line-height: 39px;
        width: auto;
        white-space: normal;border-bottom:none}
    .content1 .table-Row {    border: 1px solid #ccc;
        margin-bottom: 15px;
        border-radius: 4px;
        padding: 0 5px;display:block;background: #282C34}
    .content1 .table-Row .Cell:before {
        position: absolute;
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        line-height: 39px;
        font-weight: 600;
    } 
    .tabledataR .table-Row .Cell:nth-of-type(1):before {
        content: "Start Date";
    }
    .tabledataR .table-Row .Cell:nth-of-type(2):before {
        content: "End Date";
    }
    .tabledataR .table-Row .Cell:nth-of-type(3):before {
        content: "Curtailment Status";
    } 
    .tabledataR .table-Row .Cell:nth-of-type(4):before {
        content: "U.E. [kWh]";
    }
    .tabledataR .table-Row .Cell:nth-of-type(5):before {
        content: "D.E. [kWh]";
    }
    .tabledataR .table-Row .Cell:nth-of-type(6):before {
        content: "A.E. [kWh]";
    }   
    .table-Row .Cell {
        border: none;
        border-bottom: none;
        position: relative;
        padding-left: 50%;
        display: block;
    }
    .table-Row a.Cell{color:#fff}
    .ULcltextfff {background: #000}
    .ULcltext000 {color: #000}
    .light .content1 .Table,  .dark .content1 .Table, .dark .UL-card-width-extralarge .medium-card {background: none;}
    .ULcltextfff{margin-top:0 !important} 
    .UL-outer-setting .ul-row .resp-miniclass {padding:10px 0 !important} 
    .ULcltextfff .pagn2, .active2, .pagn2:hover{margin-right: 5px}
    .pagination-position {position: relative; bottom: 5px;}
    .UL-contantright .dropzone {margin-right:0}
    .UL-card-width-extralarge{min-width:100%;max-width:100%;}
    .ULoverflow-hidden { overflow: revert}
    
    .monthtable .table-Row .Cell:nth-of-type(1):before {
        content: "Month";
    } 
    .monthtable .table-Row .Cell:nth-of-type(2):before {
        content: "Start Date";
    } 
    .monthtable .table-Row .Cell:nth-of-type(3):before {
        content: "End Date";
    } 
    .monthtable .table-Row .Cell:nth-of-type(4):before {
        content: "A.E. [kWh]";
    } 
    .monthtable .table-Row .Cell:nth-of-type(5):before {
        content: "D.E. [kWh]";
    }
    .monthtable .table-Row .Cell:nth-of-type(6):before {
        content: "U.E. [kWh]";
    }  
    .monthtable .table-Row .Cell:nth-of-type(7):before {
        content: "Events";
    }    
    .monthtable .table-Row .Cell:nth-of-type(8):before {
        content: "Actions";
    }
    
    .UL-innerlogin .UL-div1 {display: none}
    .centerDiv .UL-innerlogin{width:95%}
    .centerDiv .UL-innerlogin .UL-div2{width:100%}

    .no-data {
        text-align: center;
        left: 0 !important;
    }

}

/**responsive table design**/
#rfs-btn {border: none}
.menuflag ul {bottom: 47px;min-width: 162px;left: unset}
.menuflag ul li span span svg {position: relative;top: 4px}
.menuflag ul li span span {color: #000}
.menu-flags-login ul {min-width: 170px;left: unset;right: 8px;margin-top: -2px}
input:checked ~ .UL-sidebar .menuflag #rfs-btn {padding:0;position: relative;left: 5px}
input:checked ~ .UL-sidebar .menuflag ul {left: 10px}
.UL-outer-setting {width: 100%;}
.UL-outer-setting .ul-row .padding-card {padding: 10px }
.resp-miniclass .ex-small-card {height:10rem;max-height: max-content} 
.resp-miniclass .ex-small-card .card-body h3 {font-size: 1.60rem}
.dark .resp-miniclass .month-text .cl000{color:#fff}
.UL-sidebar,.ul-sidebar-height{transition: All .50s ease-out}
.UL-hambargarmenu i {cursor: pointer;}
/* @media screen and (max-width: 1000px) {
    input:checked ~ .UL-sidebar{display: none}
    input:checked ~ .UL-contantright, .UL-contantright div,.main-wrap .UL-contantright {margin-left:0 !important}
    input:checked ~ label.UL-hambargarmenu {margin-left: 0;left: 20px;}
    .ul-row .resp-miniclass {width: 100%;max-width: 100%;flex: auto}
    .UL-outer-setting {padding-right: 0}
    .light .month-text span {color:#000}
    .dark .month-text span {color:#fff}
    .content1 .Cell{border-bottom: none; width: auto;}
    .Heading div {position: absolute;top: -9999px;left: -9999px }
    .content1 .Cell p {line-height: 39px;
        width: auto;
        white-space: normal;border-bottom:none}
    .light .content1 .table-Row {    
        border: 1px solid #ccc;
        margin-bottom: 15px;
        border-radius: 4px;
        padding: 0 5px;
        display:block;
        background: #FFF}
    .dark .content1 .table-Row {
        background-color: #282C34;
    }
    .content1 .table-Row .Cell:before {
        position: absolute;
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        line-height: 39px;
        font-weight: 600;
    } 
    .tabledataR .table-Row .Cell:nth-of-type(1):before {
        content: "Timestamp";
    } 
    .tabledataR .table-Row .Cell:nth-of-type(2):before {
        content: "Curtailment Status";
    } 
    .tabledataR .table-Row .Cell:nth-of-type(3):before {
        content: "U.E. [kWh]";
    } 
    .tabledataR .table-Row .Cell:nth-of-type(4):before {
        content: "A.E. [kWh]";
    }       
    .table-Row .Cell {
        border: none;
        border-bottom: none;
        position: relative;
        padding-left: 50%;
        display: block;
    }
    .table-Row a.Cell{color:#fff}
    .UL-pagination {background: #000} 
    .UL-pagination{margin-top:0 !important} 
    .UL-outer-setting .ul-row .resp-miniclass {padding:10px 0 !important} 
    .UL-pagination .pagn2, .active2, .pagn2:hover{margin-right: 5px}
    .UL-contantright .dropzone {margin-right:0}
    .UL-card-width-extralarge{min-width:100%;max-width:100%;}
    .ULoverflow-hidden { overflow: revert}
    
    .monthtable .table-Row .Cell:nth-of-type(1):before {
        content: "Month";
    } 
    .monthtable .table-Row .Cell:nth-of-type(2):before {
        content: "Start Date";
    } 
    .monthtable .table-Row .Cell:nth-of-type(3):before {
        content: "End Date";
    } 
    .monthtable .table-Row .Cell:nth-of-type(4):before {
        content: "A.E. [kWh]";
    } 
    .monthtable .table-Row .Cell:nth-of-type(5):before {
        content: "D.E. [kWh]";
    }    
    .monthtable .table-Row .Cell:nth-of-type(6):before {
        content: "Events";
    }    
    .monthtable .table-Row .Cell:nth-of-type(7):before {
        content: "Actions";
    }

    .UL-innerlogin .UL-div1 {display: none}
    .centerDiv .UL-innerlogin{width:95%}
    .centerDiv .UL-innerlogin .UL-div2{width:100%}
} */


