.container {
  height: 100vh;
}

.registrationFormContainer {
  width: 24rem;
}

.registrationFormLegend {
  width: 14rem;
  background: #dee2e6;
}