.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    background-color: #ffffff;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.SideDrawerLogo {
    height: 11%;
    margin-bottom: 32px;
}

input:checked ~ .Content {
    margin-left: 70px;
}