.Menubar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #C02032;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    /*z-index: 90;*/
}

.Menubar nav {
    height: 100%;
}

.MenubarLogo {
    height: 80%;
} 

@media (max-width: 499px) {
    .DesktopOnly > ul, .DesktopOnly > div  {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .theme-button {
        margin-right: 53px !important;
    }
}
.Ul-float-revert {
    float: revert !important;
}