.Content {
    margin-top: 72px;
    width: 100%;
    
}

.menu-flags-login .flag-select__options {
    right: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: 1px solid #F9F9F9;
    margin-top: 0px;
}