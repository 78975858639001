/* .loginFormContainer {
    width: 24rem;
  } */

.centerDiv {
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.UL-innerlogin {
  width: 70%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  height: 600px;
}
.UL-div1 {
  width: 50%;
  background-color: #f4f4f4;
}
.UL-div2 {
  width: 50%;
  background-color: #fff;
}
.UL-logo {
  height: 80px;
  width: 80px;
  margin: 31px auto 41px auto;
}
.UL-logo svg {
  fill: #c02032;
}
.UL-login-text {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}
.UL-padding43rem {
  padding: 30px;
}
.UL-mb-6 {
  margin-bottom: 2rem;
}
.UL-mb-9 {
  margin-bottom: 3rem;
}
.UL-mb05 {
  margin-bottom: 0.5rem;
}
.UL-signup-text {
  text-align: center;
  margin-top: 15px;
}
.UL-signup-text span {
  padding-left: 10px;
  color: #0088ff;
}
.col7 {
  color: #777;
}
.UL-pwd-eye {
  position: absolute;
  top: 42px;
  right: 14px;
}
.UL-plateform-text {
  text-align: center;
  margin-top: 6rem;
  font-size: 20px;
  color: #585858;
  font-weight: 500;
}
.UL-plateform-subtext {
  text-align: center;
  margin-top: 1rem;
  color: #a7a7a7;
}
.UL-login-Image {
  background: url('../../../assets/images/DWF_-_Photo_1.jpg')
    center 0 no-repeat transparent;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-size: contain;
  padding: 12rem;
}
